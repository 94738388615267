import React from 'react'

const Error404 = () => {
    return (
        <div className={'subpage'}>
            <div className={'container'}>
               
            </div>
        </div>
    )
}

export default Error404;
